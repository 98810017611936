import React from 'react';
import './App.css';
import { withTranslation } from 'react-i18next';
import APIData from "./components/APIData/APIData";
import AwsAuth from "./components/AwsAuth/AwsAuth";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accessToken: null,
      isLoggedIn: false,
    };
  }

  setAccessToken(arg) {
    this.setState({accessToken: arg});
  }

  setLoggedIn(arg) {
    this.setState({isLoggedIn: arg});
  }

  render() {
    if (this.state.isLoggedIn) {
      return (
        <div className="App">
          <img src={process.env.PUBLIC_URL + "/assets/images/thermovault-logo-white.svg"} className="App-logo" alt="logo" />
          <AwsAuth key="cognito_auth" isLoggedIn={this.state.isLoggedIn} setLoggedIn={this.setLoggedIn.bind(this)} setAccessToken={this.setAccessToken.bind(this)} />
          <a className="App-link" href={this.props.t('water_heater_manual.link')}>
            {this.props.t('water_heater_manual.label')}
          </a>
          <APIData token={this.state.accessToken} setLoggedIn={this.setLoggedIn.bind(this)}/>
        </div>
      )
    }

    return (
      <div className="App">
        <img src={process.env.PUBLIC_URL + "/assets/images/thermovault-logo-white.svg"} className="App-logo" alt="logo" />
        <div style={{textAlign: 'right', fontSize: '6vw', alignSelf: 'end'}}>SUPPORT</div>
        <div style={{flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', fontSize: '3vh'}}>
          <div>
            <div>{this.props.t('contact_us.label')}:</div>
            <div>
              <ul>
                <li>contact@thermovault.com</li>
                <li>+32 456 22 61 37</li>
              </ul>
            </div>
          </div>
        </div>
        <a className="App-link" href={this.props.t('water_heater_manual.link')}>
          {this.props.t('water_heater_manual.label')}
        </a>

        <AwsAuth key="cognito_auth" isLoggedIn={this.state.isLoggedIn} setLoggedIn={this.setLoggedIn.bind(this)} setAccessToken={this.setAccessToken.bind(this)} />
      </div>
    );
  }
}

export default withTranslation()(App);
